<template>
  <v-app>
    <v-main>
      <div class="app-container">
        <!-- Exchange rate -->
        <div class="tile tile__exchange-rate tile__exchange-rate--primary">
          <exchange-rate
            :currency-pair="primaryCurrency.pair"
            :refresh-interval="primaryCurrency.refresh"
            :source="primaryCurrency.source"
            :timezone="localTimezone"
          />
        </div>

        <!-- History chart -->
        <div class="tile tile__history-chart">
          <history-chart
            :color="primaryChart.color"
            :currency-pair="primaryCurrency.pair"
            :rate-days="primaryChart.days"
            :refresh-interval="primaryChart.refresh"
            :source="primaryChart.source"
            :timezone="localTimezone"
          />
        </div>

        <div class="tile tile__group">
          <!-- Secondary exchange rate -->
          <div class="tile tile__exchange-rate tile__exchange-rate--secondary">
            <exchange-rate
              :currency-pair="secondaryCurrency.pair"
              :refresh-interval="secondaryCurrency.refresh"
              :source="secondaryCurrency.source"
              :timezone="localTimezone"
              secondary
            >
              <template v-slot:trend>
                <trend-chart
                  :color="secondaryChart.color"
                  :currency-pair="secondaryCurrency.pair"
                  :rate-days="secondaryChart.days"
                  :refresh-interval="secondaryChart.refresh"
                  :source="secondaryChart.source"
                  :timezone="localTimezone"
                />
              </template>
            </exchange-rate>
          </div>

          <!-- Clocks -->
          <div class="tile tile__clocks">
            <clocks :timezones="clockTimezones" />
          </div>
        </div>
      </div>

      <info-dialog />
      <error-dialog />
    </v-main>

    <v-app-bar app bottom class="app-bar" color="light-blue" dark dense fixed>
      <v-app-bar-nav-icon @click="showInfoDialog">
        <v-icon>monetization_on</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Harmony</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn href="https://eternalapps.pl" target="_blank" text x-small>
        Eternal Apps
      </v-btn>
    </v-app-bar>

    <v-snackbar v-model="newVersion" timeout="-1" top>
      Pojawiła się nowa wersja aplikacji

      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="reloadApplication">
          Odśwież
        </v-btn>
      </template>
    </v-snackbar>

    <div ref="about" class="hidden">
      <p>
        <strong>Harmony</strong>&nbsp;<code>/ ˈhɑr mə ni /</code>
        &mdash; spójny, uporządkowany lub przyjemny układ części.
      </p>
      <p>Aplikacja pokazuje bieżący oraz historyczny kurs wybranych walut, a także czas.</p>
      <p>
        Ikony stworzone przez
        <v-btn href="https://www.flaticon.com/authors/freepik" target="_blank" text small>Freepik</v-btn>
      </p>
      <p v-html="'Wersja: {{POST_BUILD_ENTERS_VERSION_HERE}}'" />
    </div>
  </v-app>
</template>

<script>
import Clocks from '@/components/Clocks.vue';
import InfoDialog from '@/components/dialogs/InfoDialog.vue';
import ExchangeRate from '@/components/ExchangeRate.vue';
import ErrorDialog from '@/components/dialogs/ErrorDialog.vue';
import HistoryChart from '@/components/HistoryChart.vue';
import AppErrorMixin from '@/mixins/app-error.mixin';
import VersionCheckMixin from '@/mixins/version-check.mixin';
import TrendChart from '@/components/TrendChart.vue';

export default {
  name: 'App',

  mounted() {
    this.initVersionCheck(process.env.VUE_APP_VERSION_CHECK_ENDPOINT);

    this.$bus.$on('app.changed', this.showNewVersionInfo);
    this.$bus.$on('app.reload', this.reloadApplication);
  },

  components: {
    TrendChart,
    Clocks,
    InfoDialog,
    ErrorDialog,
    HistoryChart,
    ExchangeRate,
  },

  mixins: [
    AppErrorMixin,
    VersionCheckMixin,
  ],

  data() {
    return {
      newVersion: false,
    };
  },

  computed: {
    clockTimezones() {
      return JSON.parse(process.env.VUE_APP_CLOCK_TIMEZONES);
    },

    localTimezone() {
      return process.env.VUE_APP_LOCAL_TIMEZONE;
    },

    primaryCurrency() {
      return JSON.parse(process.env.VUE_APP_PRIMARY_CURRENCY);
    },

    primaryChart() {
      return JSON.parse(process.env.VUE_APP_PRIMARY_CHART);
    },

    secondaryCurrency() {
      return JSON.parse(process.env.VUE_APP_SECONDARY_CURRENCY);
    },

    secondaryChart() {
      return JSON.parse(process.env.VUE_APP_SECONDARY_CHART);
    },
  },

  methods: {
    showInfoDialog() {
      this.$bus.$emit(
        'dialog.info.show',
        'O aplikacji',
        this.$refs.about.innerHTML,
      );
    },

    showNewVersionInfo() {
      this.newVersion = true;
    },

    reloadApplication() {
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}

.app-container {
  padding: 10px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: #eceff1;

  @media (min-width: 600px) {
    padding: 20px;
  }
}

.tile {
  $root: &;

  display: flex;

  & + & {
    margin-top: 10px;

    @media (min-width: 600px) {
      margin-top: 20px;
    }
  }

  &__exchange-rate {
    &--primary {
      flex-basis: 40%;
    }

    &--secondary {
      @media (min-width: 600px) {
        flex-basis: 50%;
        width: 50%;
      }
    }
  }

  &__history-chart {
    flex-basis: 40%;
    height: 40%;
    flex-shrink: 0;

    @media (min-width: 600px) {
      flex-basis: 35%;
      height: 35%;
    }
  }

  &__clocks {
    @media (min-width: 600px) {
      flex-basis: 50%;
    }
  }

  &__group {
    flex-basis: 25%;

    & > #{$root} + #{$root} {
      @media (min-width: 600px) {
        margin-top: 0;
        margin-left: 20px;
      }
    }
  }
}
</style>

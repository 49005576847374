import ProxyClient from './client/ProxyClient';

const host = 'https://api.nbp.pl';

export default {
  async getRate(symbol) {
    try {
      const response = await fetch(
        ProxyClient.buildUri(
          `${host}/api/exchangerates/rates/A/${symbol[0].toUpperCase()}`,
        ), {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      const { rates } = await response.json();
      const rate = rates.pop();

      return {
        value: rate.mid,
        date: rate.effectiveDate,
      };
    } catch (e) {
      return null;
    }
  },

  async getRates(symbol, from, to, maximumRates) {
    try {
      const response = await fetch(
        ProxyClient.buildUri(
          `${host}/api/exchangerates/rates/A/${symbol[0].toUpperCase()}/last/${maximumRates || 1}`,
        ), {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      const { rates } = await response.json();

      return rates.slice().map((rate) => {
        const { mid, effectiveDate } = rate;

        return {
          value: mid,
          date: effectiveDate,
        };
      });
    } catch (e) {
      return [];
    }
  },

  getSourceName() {
    return 'nbp.pl';
  },

  getSourceUrl() {
    return 'https://nbp.pl';
  },
};

import Vue from 'vue';
import moment from 'moment-timezone';
import App from './App.vue';
import store from './store';
import vuetify from './plugins/vuetify';
import EventBus from './plugins/event-bus';

moment.locale(vuetify.framework.lang.current);

Vue.config.productionTip = false;
Vue.prototype.$moment = moment;
Vue.prototype.$bus = EventBus;

new Vue({
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

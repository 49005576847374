<template>
  <v-card class="clocks">
    <v-card-text class="clocks__content">
      <clock v-for="timezone in timezones" :key="timezone" :timezone="timezone" />
    </v-card-text>
  </v-card>
</template>

<script>
import Clock from '@/components/elements/Clock.vue';

export default {
  name: 'Clocks',

  components: {
    Clock,
  },

  props: {
    timezones: Array,
  },
};
</script>

<style lang="scss" scoped>
.clocks {
  width: 100%;
  display: flex;

  &__content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }
}
</style>

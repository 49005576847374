import NBPRepository from '@/repositories/NBPRepository';
import StooqRepository from '@/repositories/StooqRepository';

const repositories = {
  nbp: NBPRepository,
  stooq: StooqRepository,
};

export default {
  exists: (name) => name in repositories,
  get: (name) => repositories[name],
};

const VersionCheckMixin = {
  data() {
    return {
      hash: {
        current: '{{POST_BUILD_ENTERS_HASH_HERE}}',
      },
    };
  },

  methods: {
    initVersionCheck(url, frequency = 1000 * 60 * 30) {
      setInterval(() => {
        this.checkVersion(url);
      }, frequency);
    },

    checkVersion(endpoint) {
      try {
        let host = process.env.VUE_APP_DATA_HOST;
        if (host === 'self') {
          host = window.location.origin;
        }

        fetch(`${host}${endpoint}?t=${new Date().getTime()}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((data) => data.json())
          .then((versionInfo) => {
            const { hash, version } = versionInfo;

            if (this.hasHashChanged(this.hash.current, hash)) {
              this.$bus.$emit('app.changed', version);
            }

            this.hash.current = hash;
          });
      } catch (e) {
        console.error('Could not get version.');
      }
    },

    hasHashChanged(current, newValue) {
      if (!current || current === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
        return false;
      }

      return current !== newValue;
    },
  },
};

export default VersionCheckMixin;

import Country from '@/helpers/country';

const CurrencyValidator = () => {
  const everyExists = (names) => {
    const country = new Country();

    return names.every(
      (currency) => country.findBy(country.field.CURRENCY, currency.toUpperCase()) !== null,
    );
  };

  return {
    everyExists,
  };
};

export default CurrencyValidator;

import Sequence from '@/helpers/sequence';

const AppErrorMixin = {
  mounted() {
    this.$bus.$on('app.error.detected', this.onErrorDetected);
    this.$bus.$on('app.error.clear', this.onErrorClear);
  },

  data() {
    return {
      errorDetected: false,
    };
  },

  methods: {
    onErrorDetected() {
      if (this.errorDetected) {
        return;
      }

      this.errorDetected = true;

      this.$store.dispatch('incrementReloadAttemptCounter');
      this.$bus.$emit(
        'dialog.error.show',
        'Ups...',
        'Nie udało się wczytać danych ze źródła. Aplikacja podejmie ponowną próbę połączenia.',
        this.getReloadTime(),
      );
    },

    onErrorClear() {
      this.errorDetected = false;

      this.$store.dispatch('clearReloadAttemptCounter');
    },

    getReloadTime() {
      return new Sequence().fibonacci(Math.min(this.$store.getters.reloadAttempts, 12) + 4) + 2;
    },
  },
};

export default AppErrorMixin;

<template>
  <v-card class="history-chart" ref="chart-container">
    <v-card-text class="history-chart__content" ref="chart-content">
      <line-chart
        :chartId="chartId"
        :data="chartData"
        :options="chartOptions"
        v-if="chartDataLoaded"
        ref="chart"
      />
      <v-progress-circular
        color="primary"
        indeterminate
        size="80"
        v-show="!chartDataLoaded"
      ></v-progress-circular>

      <v-tooltip top v-model="tooltip">
        <template v-slot:activator="{ attrs }">
          <v-btn class="info-btn" icon v-bind="attrs" @click="tooltip = !tooltip">
            <v-icon color="grey lighten-1" small>info</v-icon>
          </v-btn>
        </template>
        <span>Źródło danych: {{ ratesRepository.getSourceName() }}</span>
      </v-tooltip>
    </v-card-text>
  </v-card>
</template>

<script>
import LineChart from '@/components/elements/LineChart.vue';
import RepositoryFactory from '@/repositories/factory/RepositoryFactory';
import ChartMixin from '@/mixins/chart.mixin';

export default {
  name: 'HistoryChart',

  created() {
    this.ratesRepository = RepositoryFactory.get(this.source);
  },

  mounted() {
    this.initializeChart();
  },

  components: {
    LineChart,
  },

  mixins: [
    ChartMixin,
  ],
};
</script>

<style lang="scss" scoped>
.history-chart {
  width: 100%;
  display: flex;

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;

    & > * {
      flex-basis: 100%;
    }
  }
}

.info-btn {
  position: absolute;
  right: 5px;
  bottom: 5px;
  margin-left: auto;
}
</style>

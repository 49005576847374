const Country = () => {
  const fields = {
    CODE: 'code',
    CURRENCY: 'currency',
    TIMEZONE: 'timezone',
  };

  const collection = [
    {
      [fields.CODE]: 'PL',
      [fields.CURRENCY]: 'PLN',
      [fields.TIMEZONE]: 'Europe/Warsaw',
      icon: 'republic-of-poland.svg',
    },
    {
      [fields.CODE]: 'USA',
      [fields.CURRENCY]: 'USD',
      [fields.TIMEZONE]: 'America/New_York',
      icon: 'united-states-of-america.svg',
    },
    {
      [fields.CODE]: 'CHN',
      [fields.CURRENCY]: 'CNY',
      [fields.TIMEZONE]: 'Asia/Hong_Kong',
      icon: 'china.svg',
    },
  ];

  const findBy = (field, value) => {
    const found = collection.filter(
      (country) => country[field].toLowerCase() === value.toLowerCase(),
    );

    return found.length === 0 ? null : found.pop();
  };

  return {
    field: fields,
    findBy,
  };
};

export default Country;

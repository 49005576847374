<template>
  <v-dialog v-model="visible" max-width="500">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text v-html="text" />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="hide">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'InfoDialog',

  mounted() {
    this.$bus.$on('dialog.info.show', this.show);
  },

  data() {
    return {
      text: null,
      title: null,
      visible: false,
    };
  },

  methods: {
    show(title, text) {
      if (this.visible) {
        return;
      }

      this.title = title;
      this.text = text;
      this.visible = true;
    },

    hide() {
      this.visible = false;
    },
  },
};
</script>

<template>
  <v-card :class="containerClasses">
    <v-card-title class="exchange-rate__title-container">
      <span>
        <v-img
          :src="require(`@/assets/images/flags/${currencyForeignTitleIconView}`)"
          class="exchange-rate__icon"
          alt=""
        />
      </span>
      <span class="exchange-rate__currency-symbol">{{ currencyForeignTitleView }}</span>
      <span>&rarr;</span>
      <span class="exchange-rate__currency-symbol">{{ currencyLocalTitleView }}</span>
      <span>
        <v-img
          :src="require(`@/assets/images/flags/${currencyLocalTitleIconView}`)"
          class="exchange-rate__icon"
          alt=""
        />
      </span>
    </v-card-title>
    <v-card-text>
      <p class="exchange-rate__display">
        <span v-html="rateView" v-show="exchangeRate !== 0"></span>
        <v-progress-circular
          color="primary"
          indeterminate
          size="50"
          v-show="exchangeRate === 0"
        ></v-progress-circular>
      </p>
    </v-card-text>
    <v-card-actions class="last-sync">
      <small v-if="showLastSync" v-html="lastSyncView"></small>

      <v-tooltip top v-model="tooltip" v-if="showLastSyncInfoButton">
        <template v-slot:activator="{ attrs }">
          <v-btn class="last-sync__info-btn" icon v-bind="attrs" @click="tooltip = !tooltip">
            <v-icon color="grey lighten-1" small>info</v-icon>
          </v-btn>
        </template>
        <span>Źródło danych: {{ ratesRepository.getSourceName() }}</span>
      </v-tooltip>
    </v-card-actions>
    <slot name="trend"></slot>
  </v-card>
</template>

<script>
import Country from '@/helpers/country';
import RepositoryFactory from '@/repositories/factory/RepositoryFactory';
import CurrencyValidator from '@/validators/currency-validator';
import SourceValidator from '@/validators/source-validator';

const country = new Country();

export default {
  name: 'ExchangeRate',

  created() {
    this.ratesRepository = RepositoryFactory.get(this.source);
  },

  mounted() {
    this.watchExchangeRateOnce();
    this.getRate();
  },

  props: {
    currencyPair: {
      type: Array,
      required: true,
      validator: CurrencyValidator.everyExists,
    },
    primary: {
      type: Boolean,
      default: true,
    },
    refreshInterval: {
      type: Number,
      required: true,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      required: true,
      validator: SourceValidator.isValid,
    },
    timezone: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      exchangeRate: 0,
      lastSync: null,
      ratesRepository: null,
      tooltip: false,
    };
  },

  computed: {
    containerClasses() {
      const classes = ['exchange-rate', `exchange-rate--${this.sizeClass}`];

      if (this.$slots.trend) {
        classes.push('exchange-rate--trend');
      }

      return classes.join(' ');
    },

    currencyForeignTitleIconView() {
      const found = country.findBy(
        country.field.CURRENCY, this.currencyPair[0],
      );

      return found !== null ? found.icon : '';
    },

    currencyForeignTitleView() {
      return this.currencyPair[0].toUpperCase();
    },

    currencyLocalTitleIconView() {
      const found = country.findBy(
        country.field.CURRENCY, this.currencyPair[1],
      );

      return found !== null ? found.icon : '';
    },

    currencyLocalTitleView() {
      return this.currencyPair[1].toUpperCase();
    },

    showLastSync() {
      return this.primary && !this.$slots.trend;
    },

    showLastSyncInfoButton() {
      return !this.$slots.trend;
    },

    lastSyncView() {
      return `Synchronizacja: ${this.lastSync ? this.lastSync.format('LL o LT') : '&mdash;'}`;
    },

    rateView() {
      return this.exchangeRate.toLocaleString(this.$vuetify.lang.current, {
        minimumFractionDigits: 4,
        maximumFractionDigits: 4,
      });
    },

    sizeClass() {
      if (this.secondary) {
        return 'secondary';
      }

      if (this.primary) {
        return 'primary';
      }

      return null;
    },
  },

  methods: {
    getRate() {
      this.exchangeRate = 0;

      this.ratesRepository.getRate(this.currencyPair)
        .then((rate) => {
          if (rate === null) {
            setTimeout(this.emitErrorDetectedEvent, Math.random() * 100);

            return;
          }

          this.exchangeRate = rate.value;
          this.lastSync = this.$moment().tz(this.timezone);
        });

      setTimeout(this.getRate, this.refreshInterval);
    },

    emitErrorDetectedEvent() {
      this.$bus.$emit('app.error.detected');
    },

    watchExchangeRateOnce() {
      this.unwatchExchangeRate = this.$watch('exchangeRate', (newValue) => {
        if (newValue > 0) {
          this.$bus.$emit('app.error.clear');

          this.unwatchExchangeRate();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.exchange-rate {
  $root: &;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;

  &--trend {
    justify-content: flex-start;
  }

  &__display {
    font-size: 5rem;
    line-height: 1.5rem;
    font-weight: 300;
    padding-top: 1.5rem;
    letter-spacing: -0.015625em !important;
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
  }

  &__title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem !important;
    font-weight: bold;
    line-height: 1rem;
    letter-spacing: -0.0083333333em !important;
  }

  &__currency-symbol {
    white-space: pre;
  }

  &--primary {
    & #{$root}__title-container {
      @media (min-width: 600px) {
        font-size: 3.75rem !important;
        line-height: 3.75rem;
        letter-spacing: -0.0083333333em !important;
      }

      & > span {
        padding: 0 10px;
      }
    }

    & #{$root}__display {
      @media (min-width: 600px) {
        font-size: 10.5rem;
        line-height: 8rem;
      }

      @media (min-width: 768px) {
        font-size: 11.5rem;
        line-height: 7rem;
      }

      @media (min-width: 1024px) {
        font-size: 17rem;
        line-height: 17rem;
      }
    }

    & #{$root}__icon {
      width: 2rem;
      height: 2rem;

      @media (min-width: 600px) {
        width: 3rem;
        height: 3rem;
      }
    }
  }

  &--secondary {
    & #{$root}__title-container {
      @media (min-width: 600px) {
        font-size: 1.75rem !important;
        line-height: 1.75rem;
        letter-spacing: -0.0083333333em !important;
      }

      & > span {
        padding: 0 5px;
      }
    }

    & #{$root}__display {
      @media (min-width: 600px) {
        font-size: 4.5rem;
        line-height: 1rem;
      }

      @media (min-width: 1024px) {
        font-size: 17rem;
        line-height: 17rem;
      }
    }

    & #{$root}__icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.last-sync {
  justify-content: center;
  z-index: 1;

  &__info-btn {
    position: absolute;
    right: 5px;
    bottom: 5px;
    margin-left: auto;
  }
}
</style>

import RepositoryFactory from '@/repositories/factory/RepositoryFactory';

const SourceValidator = () => {
  const isValid = (name) => RepositoryFactory.exists(name);

  return {
    isValid,
  };
};

export default SourceValidator;

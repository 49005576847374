import Vue from 'vue';
import Vuex from 'vuex';
import Persistence from '@/plugins/persistence';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    Persistence.plugin,
  ],

  state: {
    reloadAttemptCounter: 0,
  },

  mutations: {
    clearReloadAttemptCounter(state) {
      state.reloadAttemptCounter = 0;
    },

    incrementReloadAttemptCounter(state) {
      state.reloadAttemptCounter += 1;
    },
  },

  actions: {
    clearReloadAttemptCounter({ commit }) {
      commit('clearReloadAttemptCounter');
    },

    incrementReloadAttemptCounter({ commit }) {
      commit('incrementReloadAttemptCounter');
    },
  },

  getters: {
    reloadAttempts(state) {
      return state.reloadAttemptCounter;
    },
  },
});

export default class ProxyClient {
  static buildUri(endpoint) {
    let host = process.env.VUE_APP_DATA_HOST;

    if (host === 'self') {
      host = window.location.origin;
    }

    return `${host}/proxy/${btoa(endpoint)}`;
  }
}

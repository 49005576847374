const Sequence = () => {
  const fibonacci = (num, memo) => {
    // eslint-disable-next-line no-param-reassign
    memo = memo || {};

    if (memo[num]) return memo[num];
    if (num <= 1) return 1;

    // eslint-disable-next-line no-param-reassign
    memo[num] = fibonacci(num - 1, memo) + fibonacci(num - 2, memo);

    return memo[num];
  };

  return {
    fibonacci,
  };
};

export default Sequence;

<template>
  <v-dialog v-model="visible" max-width="500">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <span v-html="text"/>

        <div class="reload-message">
          <strong class="reload-message__text">Ponowna próba połączenia</strong>
          <v-chip color="red" outlined>{{ reloadTimeView }}</v-chip>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green" text @click="reloadApplication">Odśwież teraz</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ErrorDialog',

  mounted() {
    this.updateTime();

    this.$bus.$on('dialog.error.show', this.show);
  },

  data() {
    return {
      text: null,
      title: null,
      secondsLeft: 0,
      visible: false,
    };
  },

  computed: {
    reloadTimeView() {
      if (this.secondsLeft > 4) {
        return `za ${this.secondsLeft} sekund`;
      }

      if (this.secondsLeft > 1) {
        return `za ${this.secondsLeft} sekundy`;
      }

      if (this.secondsLeft > 0) {
        return `za ${this.secondsLeft} sekundę`;
      }

      return 'w trakcie';
    },
  },

  methods: {
    show(title, text, secondsLeft) {
      this.title = title;
      this.text = text;
      this.secondsLeft = secondsLeft;
      this.visible = true;
    },

    reloadApplication() {
      this.$bus.$emit('app.reload');
    },

    updateTime() {
      this.secondsLeft -= 1;

      if (this.secondsLeft === 0) {
        this.reloadApplication();

        return;
      }

      const time = this.$moment();

      setTimeout(this.updateTime, 1000 - time.format('SSS'));
    },
  },
};
</script>

<style lang="scss" scoped>
.reload-message {
  margin-top: 1rem;
  display: flex;
  align-items: center;

  &__text {
    padding-right: 0.35rem;
  }
}
</style>

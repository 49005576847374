import CurrencyValidator from '@/validators/currency-validator';
import SourceValidator from '@/validators/source-validator';

const ChartMixin = {
  props: {
    color: {
      type: String,
    },
    currencyPair: {
      type: Array,
      required: true,
      validator: CurrencyValidator.everyExists,
    },
    rateDays: {
      type: Number,
      required: true,
    },
    refreshInterval: {
      type: Number,
      required: true,
    },
    source: {
      type: String,
      required: true,
      validator: SourceValidator.isValid,
    },
    timezone: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: `${this.currencyPair[0]}/${this.currencyPair[1]}`,
            backgroundColor: this.color,
            data: [],
          },
        ],
      },
      chartDataLoaded: false,
      chartId: Math.random().toString(36).substring(7),
      height: 1,
      ratesRepository: null,
      simple: false,
      tooltip: false,
      width: 1,
    };
  },

  computed: {
    chartOptions() {
      const defaultOptions = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
      };
      const simpleOptions = {
        tooltips: {
          enabled: false,
        },
        animation: {
          duration: 0,
        },
        hover: {
          animationDuration: 0,
        },
        responsiveAnimationDuration: 0,
        scales: {
          yAxes: [{
            display: false,
          }],
          xAxes: [{
            display: false,
          }],
        },
      };

      if (!this.simple) {
        return defaultOptions;
      }

      return { ...defaultOptions, ...simpleOptions };
    },
  },

  methods: {
    initializeChart() {
      this.watchChartLoadedOnce();
      this.getRates();

      window.addEventListener('resize', this.resizeChartContainer);
    },

    getRates() {
      this.chartDataLoaded = false;

      const to = this.$moment()
        .tz(this.timezone);
      const from = this.$moment()
        .tz(this.timezone)
        .subtract(this.rateDays + 15, 'days');

      this.ratesRepository.getRates(this.currencyPair, from, to, this.rateDays)
        .then((rates) => {
          if (rates.length === 0) {
            setTimeout(this.emitErrorDetectedEvent, Math.random() * 100);

            return;
          }

          this.chartData.labels = this.makeLabels(rates);
          this.chartData.datasets[0].data = rates.slice()
            .map((rate) => rate.value * 1);
          this.chartDataLoaded = true;

          this.resizeChartContainer();
        });

      setTimeout(this.getRates, this.refreshInterval);
    },

    makeLabels(rates) {
      let labelCount = -1;

      return rates.slice()
        .map((rate) => {
          labelCount += 1;

          return labelCount % 5 === 0 ? this.$moment(rate.date)
            .format('D MMM') : '';
        });
    },

    resizeChartContainer() {
      let chartContainer = this.$refs['chart-container'];
      if (chartContainer && this.$refs['chart-container'].$el) {
        chartContainer = this.$refs['chart-container'].$el;
      }

      let chartContent = this.$refs['chart-content'];
      let padding = 0;
      if (chartContent) {
        if (this.$refs['chart-content'].$el) {
          chartContent = this.$refs['chart-content'].$el;
        }

        padding = Number.parseFloat(window.getComputedStyle(chartContent, null)
          .getPropertyValue('padding'));
      }

      let chartCanvas = this.$refs.chart;
      if (chartCanvas && this.$refs.chart.$el) {
        chartCanvas = this.$refs.chart.$el.querySelector('canvas');
      }

      if (!chartCanvas) {
        setTimeout(this.resizeChartContainer, 10);
        return;
      }

      chartCanvas.style.height = `${(chartContainer.parentElement.clientHeight - padding * 2) * this.height}px`;
      chartCanvas.style.width = `${(chartContainer.parentElement.clientWidth - padding * 2) * this.width}px`;
    },

    emitErrorDetectedEvent() {
      this.$bus.$emit('app.error.detected');
    },

    watchChartLoadedOnce() {
      this.unwatchChartLoaded = this.$watch('chartDataLoaded', (newValue) => {
        if (newValue) {
          this.$bus.$emit('app.error.clear');

          this.unwatchChartLoaded();
        }
      });
    },
  },
};

export default ChartMixin;

<template>
  <div class="trend-chart" ref="chart-container">
    <div class="trend-chart__content" ref="chart-content">
      <line-chart
        :chartId="chartId"
        :data="chartData"
        :options="chartOptions"
        v-if="chartDataLoaded"
        ref="chart"
      />
    </div>
  </div>
</template>

<script>
import ChartMixin from '@/mixins/chart.mixin';
import LineChart from '@/components/elements/LineChart.vue';
import RepositoryFactory from '@/repositories/factory/RepositoryFactory';

export default {
  name: 'TrendChart',

  created() {
    this.ratesRepository = RepositoryFactory.get(this.source);
    this.simple = true;
    this.height = 0.4;
  },

  mounted() {
    this.initializeChart();
  },

  components: {
    LineChart,
  },

  mixins: [
    ChartMixin,
  ],
};
</script>

<style lang="scss" scoped>
.trend-chart {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  display: flex;

  &__content {
     display: flex;
     justify-content: center;
     align-items: center;

  & > * {
      flex-basis: 100%;
    }
  }
}
</style>

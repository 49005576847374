<template>
  <div class="clock">
    <span class="clock__icon-container">
      <v-img
        :src="require(`@/assets/images/flags/${iconView}`)"
        class="clock__icon"
        :alt="countryCodeView"
      />
    </span>
    <span class="clock__time">{{ timeView }}</span>
  </div>
</template>

<script>
import Country from '@/helpers/country';

export default {
  name: 'Clock',

  mounted() {
    this.updateTime();
  },

  props: {
    timezone: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      time: this.$moment()
        .tz(this.timezone),
    };
  },

  computed: {
    country() {
      const country = new Country();

      return country.findBy(country.field.TIMEZONE, this.timezone);
    },

    countryCodeView() {
      return this.country.code;
    },

    iconView() {
      return this.country.icon;
    },

    timeView() {
      return this.time.format('LT');
    },
  },

  methods: {
    updateTime() {
      this.time = this.$moment()
        .tz(this.timezone);

      setTimeout(this.updateTime, 60000 - this.time.format('SSS'));
    },
  },
};
</script>

<style lang="scss" scoped>
.clock {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__icon {
    width: 4rem;
    height: 4rem;
  }

  &__time {
    margin-left: auto;
    font-size: 4rem;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.87);
  }
}
</style>

import ProxyClient from './client/ProxyClient';

const host = 'https://stooq.com/q';

export default {
  async getRate(symbol) {
    const search = `${symbol[0].toLowerCase()}${symbol[1].toLowerCase()}`;

    try {
      const response = await fetch(
        ProxyClient.buildUri(
          `${host}/l/?s=${search}&f=sd2t2c&e=json`,
        ), {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      const { symbols } = await response.json();
      const rate = symbols.pop();

      return {
        value: rate.close,
        date: rate.date,
      };
    } catch (e) {
      return null;
    }
  },

  async getRates(symbol, from, to, maximumRates) {
    const search = `${symbol[0].toLowerCase()}${symbol[1].toLowerCase()}`;

    try {
      const response = await fetch(
        ProxyClient.buildUri(
          `${host}/d/l/?s=${search}&d1=${from.format('YYYYMMDD')}&d2=${to.format('YYYYMMDD')}&i=d`,
        ),
      );

      const body = await response.text();

      if (body.startsWith('Exceeded the daily hits limit')) {
        return [];
      }

      const rates = this.convertCSVToJSON(body);

      return rates.slice(0, maximumRates)
        .map((rate) => {
          const { Close, Date } = rate;

          return {
            value: Close,
            date: Date,
          };
        });
    } catch (e) {
      return [];
    }
  },

  convertCSVToJSON(str, delimiter = ',') {
    const titles = str
      .slice(0, str.indexOf('\n'))
      .split(delimiter)
      .map((title) => title.trim());

    return str
      .slice(str.indexOf('\n') + 1)
      .split('\n')
      .map((row) => {
        const values = row.trim().split(delimiter);

        return titles.reduce((object, curr, i) => {
          // eslint-disable-next-line no-param-reassign
          object[curr] = values[i];
          return object;
        }, {});
      });
  },

  getSourceName() {
    return 'stooq.com';
  },

  getSourceUrl() {
    return 'https://stooq.com';
  },
};
